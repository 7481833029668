.menuApp {
	display: flex;
	// justify-content: space-between;
	justify-content: flex-start;
	margin-left: auto;
	margin-right: 60px;

	@media screen and (max-width: 1100px) {
		margin-right: auto;
		margin-left: auto;
	}
	.btn-active {
		color: #f5f5f5;
	}
	.btn-disable {
		color: #8e8e93;
	}
	.btn {
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 32px;
		letter-spacing: normal;
		padding: 0;
		text-transform: none;
		@media screen and (max-width: 1400px) {
			font-size: 18px;
			line-height: 20px;
		}
		@media screen and (max-width: 1280px) {
			font-size: 12px;
			line-height: 15px;
		}
		@media screen and (max-width: 800px) {
			font-size: 12px;
			line-height: 15px;
		}
	}
	.btn-left {
		margin-left: 40px;
		@media screen and (max-width: 800px) {
			margin-left: 20px;
		}
	}
}
