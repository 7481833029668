.button-confirm {
	.btn-confirm {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 32px;
		background: linear-gradient(225.17deg, #ffa635 4.01%, #ff3f5b 95.91%);
		background-color: #ffa635;
		border-radius: 6px;
		// font-family: Circular Std;
		font-family: "Circular Medium", Verdana;
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		text-transform: none;
		color: #f5f5f5;
		transition: background-color 1s ease-out;
	}
	.btn-confirm:hover {
		// background: linear-gradient(224.32deg, #3864ff 0%, #ff3f5b 95.47%) border-box;
		background: #ff3f5b;
	}
	.btn-noconfirm {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 16px 32px;

		background: #2c2c2e;
		border-radius: 6px;
		// font-family: Circular Std;
		font-family: "Circular Medium", Verdana;
		font-weight: 500;
		font-size: 22px;
		line-height: 32px;
		text-transform: none;
		color: #f5f5f5;
		transition: background-color 1s ease-out;
	}
}
