.logo {
	display: flex;
	width: 114.29px;
	height: 67px;
	background-color: #0d0d0d;
	@media screen and (max-width: 800px) {
		width: 70px;
		height: 40px;
	}

	.logo-img {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}
