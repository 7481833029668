@font-face {
	font-family: "Circular Medium";
	font-weight: bold;
	font-display: swap;
	/* src: url("./accets/fonts/Circular Medium/Circular Medium.ttf") format("truetype"); */
	src: local("Circular Medium"),
		url("./accets/fonts/Circular Medium/@font-face/3d85dc16e94da9358d451666fdbc3398.woff")
			format("woff"),
		url("./accets/fonts/Circular Medium/@font-face/3d85dc16e94da9358d451666fdbc3398.woff2")
			format("woff2"),
		url("./accets/fonts/Circular Medium/@font-face/3d85dc16e94da9358d451666fdbc3398.ttf")
			format("truetype"),
		url("./accets/fonts/Circular Medium/@font-face/3d85dc16e94da9358d451666fdbc3398.svg")
			format("svg");
}

body {
	margin: 0;
	padding: 0;
	font-family: "Circular Medium", Verdana;
	/* font-family: "Circular Std"; */

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* background: #0d0d0d; */
	/* font-family: -apple-system, BlinkMacSystemFont, "Circular Std", "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
