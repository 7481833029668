.circular-loading-api {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	opacity: 0.5;
	background-color: black;
	width: 100%;
	height: 100vh;

	.MuiCircularProgress-svg {
		circle {
			stroke-linecap: round;
			stroke: url(#loading_linear);
		}
	}
	svg {
	}
}
