.slider-client {
	// margin-top: 300px;
	position: relative;
	width: 100%;
	height: 550px;

	@media screen and(max-width: 1400px) {
		min-height: 600px;
	}
	@media screen and (max-width: 1279px) {
		margin-top: 600px;
	}
	@media screen and (max-width: 1100px) {
		margin-top: 100px;
		height: 650px;
	}
	@media screen and (max-width: 1000px) {
		margin-top: 0px;
		height: 700px;
	}
	@media screen and(max-width: 420px) {
		width: 80vw;
		height: 800px;
	}
	.slider-client_title {
		width: 100%;
		text-align: center;
		font-family: "Circular Medium", Verdana;
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 60px;
		color: #f5f5f5;
		margin-bottom: 80px;
		@media screen and(max-width: 420px) {
			font-size: 40px;
			line-height: 60px;
			margin-bottom: 120px;
		}
	}
	#i1,
	#i2,
	#i3,
	#i4,
	#i5 {
		display: none;
	}
	.slider_testimonials {
		position: absolute;
		width: 85%;
		left: 50%;
		transform: translate(-50%, 0);

		.list-testimonials {
			width: 100%;
			position: absolute;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// margin-top: 40px;
			left: 50%;
			transform: translate(-50%, 0);

			& > * {
				// position: absolute;
			}
			@media screen and (max-width: 420px) {
				width: 200px;
			}
			.prev-client,
			.next-client {
				width: 56px;
				// height: inherit;
				position: absolute;
				color: #8e8e93;
				z-index: 99;
				transition: 0.45s;
				cursor: pointer;
				@media screen and(max-width: 420px) {
					width: 0px;
				}
			}
			.next-client {
				right: 0;
			}
			.prev-client {
				left: 0;
			}
			.prev-client:hover,
			.next-client:hover {
				transition: 0.3s;
				color: #ffffff;
			}
			.prev-client,
			.next-client {
				height: 100%;
				.client_arrow {
					top: 35%;
					position: absolute;
					font-size: 50pt;
					// transform: translateY(-50%);
					@media screen and (max-width: 420px) {
						top: 25%;
					}
				}
			}
			.next-client .client_arrow {
				right: 0;
				transform: translateX(50px);
			}
			.prev-client .client_arrow {
				transform: translateX(-50px);
			}
		}
	}
	#nav_slide_testimonials {
		position: absolute;
		width: 100%;
		// bottom: 17%;
		height: 11px;
		text-align: center;
		z-index: 99;
		cursor: default;
		top: 105%;

		.dots {
			top: -5px;
			width: 10px;
			height: 10px;
			margin: 0 5px;
			position: relative;
			border-radius: 100%;
			display: inline-block;
			background-color: #ffffff;
			opacity: 0.3;
			transition: 0.4s;
		}
	}
	.slider_testimonials {
		z-index: -1;
	}
	#i1:checked ~ #one,
	#i2:checked ~ #two,
	#i3:checked ~ #three,
	#i4:checked ~ #four,
	#i5:checked ~ #five {
		z-index: 9;
		animation: scroll 1s ease-in-out;
	}
	#i1:checked ~ #nav_slide_testimonials #dot1,
	#i2:checked ~ #nav_slide_testimonials #dot2,
	#i3:checked ~ #nav_slide_testimonials #dot3,
	#i4:checked ~ #nav_slide_testimonials #dot4,
	#i5:checked ~ #nav_slide_testimonials #dot5 {
		background-color: #ffa635;
		opacity: 1;
	}
}

@keyframes scroll {
	0% {
		opacity: 0.4;
	}
	100% {
		opacity: 1;
	}
}

//
.testimonials-block {
	position: relative;
	width: 500px;
	min-height: 200px;
	height: 100%;
	max-height: 350px;
	background: #1c1c1e;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	
	@media screen and(max-width: 1800px) {
		width: 400px;
	}
	@media screen and(max-width: 1400px) {
		width: 350px;
	}
	@media screen and (max-width: 1279px) {
		width: 300px;
	}
	@media screen and(max-width: 1100px) {
		width: 270px;
	}
	@media screen and(max-width: 800px) {
		width: 200px;
	}
	@media screen and(max-width: 420px) {
		
		
	}

	.tm-photo {
		position: absolute;
		left: 50%;
		transform: translate(-50px, -50px);
		.tm-img{
			width: 128px;
			height: 128px;
			border-radius: 50%;
		}
	}
	.tm-name {
		margin-top: 96px;
		font-family: Helvetica;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		display: flex;
		align-items: center;
		text-align: center;
		color: #f5f5f5;
		text-overflow: ellipsis;
	}
	.tm-review {
		// padding-top: 15px;
		margin-top: 16px;
		margin-bottom: 40px;
		width: 84%;
		font-family: Helvetica;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		line-height: 26px;
		color: #f5f5f5;
		overflow:auto;
		
	}
	.tm-review::-webkit-scrollbar {
		width: 25px;
		
	}
	.tm-review::-webkit-scrollbar-track {
		background-color: none;
	}
}


